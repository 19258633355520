@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;700;900&display=swap");

:root {
	--darkest: #1f1f38;
	--darker: #2c2c6c;
	--medium: #4db5ff;
	--secondary: #ff4dc7;
	--light: hsl(205, 100%, 85%);
}

* {
	font-family: "Poppins", sans-serif;
	margin: 0;
	box-sizing: border-box;
}

.custom-tooltip {
	--bs-tooltip-bg: var(--bs-secondary);
}

.main-tech-badge {
	background-color: var(--secondary);
	color: var(--darkest) !important;

	border-radius: 15px;
	margin-right: 5px;
	margin-top: 5px;
}

.my-badge {
	background-color: var(--medium);
	color: var(--darkest) !important;

	border-radius: 15px;
	margin-right: 5px;
	margin-top: 5px;
}

.project-img-and-container {
	height: 450px !important;
}

.list-group-item {
	font-size: small;
}

@media screen and (width > 900px) {
	.project-img-and-container {
		height: 350px !important;
	}
}

@media screen and (width < 600px) {
	.skill-card {
		margin: 7px 60px !important;
	}

	.list-group-item {
		font-size: 0.75rem;
	}
}


.nav-link {
	color: hsl(240, 42%, 45%) !important;
}

.nav-link:hover {
	color: var(--darkest) !important;
}

.active {
	color: hsl(240, 42%, 45%) !important;
}






#projects .link, #projects .btn {
	background-color: transparent;
	color: white;
	border: 1px solid white;
}

#projects .link:hover, #projects .btn:hover {
	background-color: var(--medium);
	color: black;
}

#projects .inactive {
	background-color: transparent;
	color: white;
}

#projects .inactive:hover {
	background-color: transparent;
	color: white;
	cursor: not-allowed;
}

#projects .my-tooltip:hover {
	cursor: pointer
}
